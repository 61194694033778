/* You can add global styles to this file, and also import other style files */
@import '@citadel/styles/citadel-style-parameters';
@import '@citadel/styles/citadel-ng-select.theme.scss';

@mixin background() {
    background-image: url('/assets/background.jpg');
    background-size: cover;
    width: 100vw;
    height: 100vh;
}
body {
    @include background(); 
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, Arial, Helvetica, sans-serif;
}
clcapp-root {
    width: 100vw;
    height: 100vh;
}
.sign-in-page {
    // @include background();
    display: grid;
    grid-template-columns: 1fr 641px 1fr;
    grid-template-rows: 1fr 496px 1fr;
    grid-template-areas: 
        ". . ."
        ". container ."
        ". . .";
    width: 100vw;
    height: 100vh;
}
.sign-in-container {
    background-color: white;
    grid-area: container;
    padding: 2rem;
}

h3 {
    color: $primary;
}